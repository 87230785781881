<template>
<div class="solution">
  <CompanyProfileBanner :index="'3'" :imageUrl="imageUrl"></CompanyProfileBanner>
  <BreadcrumbAndSubtag
    :tabList="tabList"
    :activeNowPath="activeNowPath !== '' ? activeNowPath : '4-1'"
    @changeActiveTabFun="changeActiveTabFun"
  ></BreadcrumbAndSubtag>
  <div class="solutionContent">
    <div class="contents">
      <ul>
        <li
          v-for="list in listData"
          :key="list.id" @click="handleClickSolutionItemFun(list.id)">
          <div class="caseList">
            <img alt="about" :src="list.imageThumbnail" />
            <div class="caseIcon">
              <span>{{ list.title }}</span>
            </div>
          </div>
          <h4>{{ list.title }}</h4>
        </li>
      </ul>
    </div>
  </div>
  <footerMain></footerMain>
</div>
</template>

<script>
import CompanyProfileBanner from '@/components/companyProfileBanner'
import BreadcrumbAndSubtag from '@/components/breadcrumbAndSubtag'
import footerMain from '@/components/footerMain'
import {mapGetters} from "vuex";

export default {
  name: "solution",
  components: {
    CompanyProfileBanner,
    BreadcrumbAndSubtag,
    footerMain
  },
  data() {
    return {
      imageUrl: '',
      pageId: '',
      tabList: [
        {key: '4-1', name: '电力数据分析'},
        {key: '4-2', name: '节能增效'},
        {key: '4-3', name: '用电安全'},
        {key: '4-4', name: '精细化运维'},
      ],
      activeNowPath: '',
      listData: [],
    }
  },
  computed: {
    ...mapGetters(['secondaryRoutingIndex'])
  },
  watch: {
    secondaryRoutingIndex: {
      handler(n,o) {
        const checkPath = (url) => {
          return [
            "4-1",
            "4-2",
            "4-3",
            "4-4",
          ].includes(url);
        }
        if(checkPath(n)) {
          this.activeNowPath = n
        } else {
          this.activeNowPath = '4-1'
          this.$store.commit('SET_CURRENT_ROUTE', '/solution')
          this.$store.commit('SET_SECONDARY_ROUTING_INDEX', '4-1')
        }
      },
      immediate: true
    },
  },
  created() {
    this.loadResourceOfPageFun();
    this.getArticleListFun()
  },
  methods: {
    changeActiveTabFun(val) {
      this.activeNowPath = val
      this.getArticleListFun()
      this.$store.commit('SET_SECONDARY_ROUTING_INDEX', val)
    },
    loadResourceOfPageFun() {
      api.getResourceOfPage({pageId: 9}).then((res) => {
        if(res.code === 100) {
          this.imageUrl = resAddressPrefix + res.data[0].images[0].imageUrl
        }else {
          this.$message.error(res.msg);
        }
      }).catch((err) => {
        if(err.msg) {
          this.$message.error(err.msg);
        }
      });
    },
    getArticleListFun() {
      const _params = {
        pageId: this.activeNowPath ? this.loadListDataFun(this.activeNowPath) : 10,
        summaryFlag: 1
      }
      api.getArticleList(_params).then((res) => {
        if(res.code === 100) {
          this.listData = res.data[0].articles
          this.pageId = res.data[0].pageId
        }else {
          this.$message.error(res.msg);
        }
      }).catch((err) => {
        if(err.msg) {
          this.$message.error(err.msg);
        }
      });
    },
    loadListDataFun(key) {
      switch (key) {
        case '4-1':
          return 10;
        case '4-2':
          return 11;
        case '4-3':
          return 12;
        case '4-4':
          return 13;
        default:
          return 10;
      }
    },
    handleClickSolutionItemFun(id) {
      this.$store.commit('SET_ARTICLES_ID', id)
      this.$router.push({
        path: '/productAndSolutionDetails',
        query: {data: btoa(encodeURIComponent(
            JSON.stringify({type: 'solution', pageId: this.pageId})
          )),
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.solution {
  background: #f3f3f3;
  .solutionContent {
    margin: auto;
    //display: table;
    //vertical-align: middle;
    .contents {
      width: 1200px;
      //height: 560px;
      padding: 2em 0;
      margin: auto;
      //display: table-cell;
      //vertical-align: middle;
      //overflow: hidden;
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        overflow: hidden;
        li {
          float: left;
          padding: 10px;
          background: #FFFFFF;
          margin: 0 10px 10px 0;
          &:nth-child(3n) {
            margin-right: 0;
          }
          .caseList {
            position: relative;
            float: left;
            overflow: hidden;
            width: 373px;
            height: 200px;
            cursor: pointer;
            margin-bottom: 10px;
            &:nth-child(4),&:nth-child(5),&:nth-child(6) {
              margin-bottom: 0;
            }
            &:nth-child(3),&:nth-child(6) {
              margin-right: 0;
            }
            &:hover {
              img {
                transform: scale(0.9);
              }
              .caseIcon {
                opacity: 1;
              }
            }
            img {
              width: 100%;
              height: 100%;
              transition: all 0.5s;
            }
            .caseIcon {
              opacity: 0;
              position: absolute;
              top: 0;
              left: 0;
              width: 386px;
              height: 200px;
              text-align: center;
              transition: all .3s;
              background: rgba(0,74,174,0.5);
              span {
                color: #FFFFFF;
                font-size: 1.4em;
                line-height: 180px;
              }
            }
          }
          h4 {
            margin: 0;
          }
        }
      }
    }
  }
}
</style>
